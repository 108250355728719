import {
  type MetricsCubeQueryOptions,
  metricsCubeQueryResponseDataSchema,
} from '@carbonfact/shared/src/types/platform/metrics-cube';
import { useFetchWithAuth } from 'app/context/SWRContext';
import useEndpoint from 'app/hooks/useEndpoint';
import base64url from 'base64url';

export default function useMetricsCubeQuery(
  options: MetricsCubeQueryOptions | null,
) {
  const fetchWithAuth = useFetchWithAuth();

  return useEndpoint(
    options
      ? // Use a hash of the options as the SWR cache key
        `/metrics-cube/query?cacheKey=${base64url.encode(JSON.stringify(options))}`
      : null,
    {
      fetcher: () =>
        fetchWithAuth<Record<string, unknown>[]>('/metrics-cube/query', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: options?.timeFrame ? 'byTimeFrame' : 'byTimePeriod',
            ...options,
          }),
        }).then((results) =>
          results?.map((data) =>
            metricsCubeQueryResponseDataSchema.parse(data),
          ),
        ),
    },
  );
}
