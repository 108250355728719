import { upperFirst } from 'lodash';
import type { useTranslations } from 'next-intl';
import { humanizeKey } from './humanizeKey';

/* Get the translated material name. */
export function getMaterialName(
  t: ReturnType<typeof useTranslations>,
  material: string,
): string {
  return getName(t, 'RawMaterials', material);
}

/* Check if the material has a translated name. */
export function hasMaterialName(
  t: ReturnType<typeof useTranslations>,
  material: string,
) {
  return t.has(`RawMaterials.${material.toUpperCase()}`);
}

/* Get the translated process step name. */
export function getProcessStepName(
  t: ReturnType<typeof useTranslations>,
  processStep: string,
) {
  return getName(t, 'ProcessSteps', processStep);
}

/* Check if the process step has a translated name. */
export function hasProcessStepName(
  t: ReturnType<typeof useTranslations>,
  processStep: string,
) {
  return t.has(`ProcessSteps.${processStep.toUpperCase()}`);
}

// Inner functions

function getName(
  t: ReturnType<typeof useTranslations>,
  prefix: string,
  key: string,
) {
  const fullKey = `${prefix}.${key.toUpperCase()}`;
  if (!t.has(fullKey)) {
    return upperFirst(humanizeKey(key));
  }
  return upperFirst(t(fullKey));
}
