import { z } from 'zod';

// General types and enums
export enum MetricsCubeMetric {
  Footprint = 'FOOTPRINT',
  Uncertainty = 'UNCERTAINTY',
  Mass = 'MASS',
  MassMeasured = 'MASS_MEASURED',
  MassWithLoss = 'MASS_WITH_LOSS',
  MassWithLossMeasured = 'MASS_WITH_LOSS_MEASURED',
  Units = 'UNITS',
  UnitsOrdered = 'UNITS_ORDERED',
  UnitsReceived = 'UNITS_RECEIVED',
  UnitsMeasured = 'UNITS_MEASURED',
}

export const metricsCubeMetricSchema = z.nativeEnum(MetricsCubeMetric);

export enum MetricsCubeObjectKind {
  Product = 'PRODUCT',
  ProductStage = 'PRODUCT_STAGE',
  Component = 'COMPONENT',
  Material = 'MATERIAL',
  RawMaterial = 'RAW_MATERIAL',
  ProcessStep = 'PROCESS_STEP',
  Facility = 'FACILITY',
  Expense = 'EXPENSE',
  DownstreamTransport = 'DOWNSTREAM_TRANSPORT',
  BusinessTravel = 'BUSINESS_TRAVEL',
  Commute = 'COMMUTE',
  CarbonAccountingScope = 'CARBON_ACCOUNTING_SCOPE',
  CarbonAccountingScopeMarketBased = 'CARBON_ACCOUNTING_SCOPE_MARKET_BASED',
  CarbonAccountingScopeLocationBased = 'CARBON_ACCOUNTING_SCOPE_LOCATION_BASED',
}

export const metricsCubeObjectKindSchema = z.nativeEnum(MetricsCubeObjectKind);

export interface MetricsCubeMetricInfo {
  name: MetricsCubeMetric;
  applicableObjectKinds: MetricsCubeObjectKind[];
}
export const metricsCubeMetricInfoSchema = z.object({
  name: metricsCubeMetricSchema,
  applicableObjectKinds: z.array(metricsCubeObjectKindSchema),
});

export interface MetricsCubeDimension {
  key: string;
  applicableMetrics: MetricsCubeMetric[];
  applicableObjectKinds: MetricsCubeObjectKind[];
}

export const metricsCubeDimensionSchema = z.object({
  key: z.string(),
  applicableMetrics: z.array(metricsCubeMetricSchema),
  applicableObjectKinds: z.array(metricsCubeObjectKindSchema),
});

export enum MetricsCubeBasicTimeFrame {
  Year = 'YEAR',
  Month = 'MONTH',
  YearToMonth = 'YTM',
}

export interface MetricsCubeTimeFrame {
  label: MetricsCubeBasicTimeFrame | string;
  isAccountSpecific: boolean;
}

export const metricsCubeTimeFrameSchema = z.object({
  label: z.string(),
  isAccountSpecific: z.boolean(),
});

export interface MetricsCubeTimePeriod {
  label: string;
  startDate: Date;
  endDate: Date;
  timeFrame: MetricsCubeTimeFrame;
}

export const metricsCubeTimePeriodSchema = z.object({
  label: z.string(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  timeFrame: metricsCubeTimeFrameSchema,
});

// Response types and schemas

export interface MetricsCubeQueryResponseData {
  metricValue: number | null;
  dimensionValues: Record<MetricsCubeDimension['key'], string | null>;
  period: MetricsCubeTimePeriod;
}

export const metricsCubeQueryResponseDataSchema = z.object({
  metricValue: z.number().nullable(),
  dimensionValues: z.record(z.string().nullable()),
  period: metricsCubeTimePeriodSchema,
});

// Query types and schemas

interface BaseMetricsCubeOptions {
  type?: 'byTimeFrame' | 'byTimePeriod';
  metric: MetricsCubeMetric;
  overMetric?: MetricsCubeMetric | null;
  firstDimension?: MetricsCubeDimension['key'] | null;
  sliceDimension?: MetricsCubeDimension['key'] | null;
  timeFrame?: MetricsCubeTimeFrame['label'] | null;
  timePeriod?: MetricsCubeTimePeriod['label'] | null;
}

const baseMetricsCubeOptionsSchema = z.object({
  metric: metricsCubeMetricSchema,
  overMetric: metricsCubeMetricSchema.nullish(),
  firstDimension: z.string().nullish(),
  sliceDimension: z.string().nullish(),
  timeFrame: z.string().nullish(),
  timePeriod: z.string().nullish(),
});

interface ByTimeFrameMetricsCubeOptions extends BaseMetricsCubeOptions {
  type?: 'byTimeFrame';
  timeFrame: MetricsCubeTimeFrame['label'];
  timePeriod?: null;
}

const byTimeFrameMetricsCubeOptionsSchema = baseMetricsCubeOptionsSchema.extend(
  {
    type: z.literal('byTimeFrame'),
    timeFrame: z.string(),
    timePeriod: z.undefined().nullable(),
  },
);

interface ByTimePeriodMetricsCubeOptions extends BaseMetricsCubeOptions {
  type?: 'byTimePeriod';
  timeFrame?: null;
  timePeriod: MetricsCubeTimePeriod['label'];
}

const byTimePeriodMetricsCubeOptionsSchema =
  baseMetricsCubeOptionsSchema.extend({
    type: z.literal('byTimePeriod'),
    timeFrame: z.undefined().nullable(),
    timePeriod: z.string(),
  });

export type MetricsCubeQueryOptions =
  | ByTimeFrameMetricsCubeOptions
  | ByTimePeriodMetricsCubeOptions;

export const metricsCubeOptionsSchema = z.discriminatedUnion('type', [
  byTimeFrameMetricsCubeOptionsSchema,
  byTimePeriodMetricsCubeOptionsSchema,
]);
