import { Climatiq } from '@carbonfact/shared/src/climatiq';
import {
  MetricsCubeMetric,
  type MetricsCubeQueryOptions,
  type MetricsCubeQueryResponseData,
} from '@carbonfact/shared/src/types/platform/metrics-cube';
import { capitalizeWords } from '@carbonfact/shared/src/utils';
import { formatCount } from '@carbonfact/shared/src/utils/formatNumber';
import {
  getMaterialName,
  getProcessStepName,
  hasMaterialName,
  hasProcessStepName,
} from '@carbonfact/shared/src/utils/getTranslations';
import { upperFirst } from 'lodash';
import { useTranslations } from 'next-intl';
import { type ReactNode, useCallback, useMemo } from 'react';

export interface MetricsCubeFormattingUtils {
  formatMetricName: (metricName?: MetricsCubeMetric | null) => string;
  getMetricValue: (d: MetricsCubeQueryResponseData) => number | null;
  formatMetricValue: (v: number | null) => string;
  metricSuffix: string;
  formatDimensionNameOrValue: (
    kayaString?: string | null,
    inDimension?: string | null,
  ) => string;
  getMetricOrDimensionDescription: (
    kayaString?: string | null,
  ) => ReactNode | undefined;
}

// A collection of printing and formatting utilities to display metrics-cube data
export default function useMetricsCubeFormattingUtils(
  options?: Partial<MetricsCubeQueryOptions>,
): MetricsCubeFormattingUtils {
  const t = useTranslations();

  const findTranslationOrNull = useCallback(
    (key: string): string | null => {
      if (t.has(key)) {
        return t(key);
      }
      return null;
    },
    [t],
  );

  const [getMetricValue, formatMetricValue, metricSuffix] = useMemo<
    [
      MetricsCubeFormattingUtils['getMetricValue'],
      MetricsCubeFormattingUtils['formatMetricValue'],
      MetricsCubeFormattingUtils['metricSuffix'],
    ]
  >(() => {
    function getUtilFns(
      metric?: MetricsCubeMetric,
      isInFraction = Boolean(options?.overMetric),
      isDenominator = false,
    ): [
      MetricsCubeFormattingUtils['getMetricValue'],
      MetricsCubeFormattingUtils['formatMetricValue'],
      MetricsCubeFormattingUtils['metricSuffix'],
    ] {
      switch (metric) {
        case MetricsCubeMetric.Footprint:
          if (isInFraction) {
            // Keep footprint in kg
            return [
              (d) => d.metricValue,
              (v) => (typeof v === 'number' ? formatCount(v) : 'n/a'),
              'kgCO2e',
            ];
          }

          return [
            (d) =>
              typeof d.metricValue === 'number' ? d.metricValue / 1000 : null, // convert kgCO2e to tCO2e
            (v) => (typeof v === 'number' ? formatCount(v) : 'n/a'),
            'tCO2e',
          ];
        case MetricsCubeMetric.Units:
        case MetricsCubeMetric.UnitsOrdered:
        case MetricsCubeMetric.UnitsReceived:
        case MetricsCubeMetric.UnitsMeasured:
          return [
            (d) => d.metricValue,
            (v) => (typeof v === 'number' ? formatCount(v) : 'n/a'),
            isDenominator
              ? t('Products.units').toLowerCase().replace(/s$/, '')
              : t('Products.units'),
          ];
        case MetricsCubeMetric.Mass:
        case MetricsCubeMetric.MassWithLoss:
        case MetricsCubeMetric.MassMeasured:
        case MetricsCubeMetric.MassWithLossMeasured:
          return [
            (d) =>
              typeof d.metricValue === 'number' ? d.metricValue / 1000 : null, // convert grams to kg
            (v) => (typeof v === 'number' ? formatCount(v) : 'n/a'),
            'kg',
          ];
        case MetricsCubeMetric.Uncertainty:
          if (isInFraction) {
            // Keep uncertainty in kg
            return [
              (d) => d.metricValue,
              (v) => (typeof v === 'number' ? formatCount(v) : 'n/a'),
              'kgCO2e',
            ];
          }

          return [
            (d) =>
              typeof d.metricValue === 'number' ? d.metricValue / 1000 : null, // convert kgCO2e to tCO2e
            (v) => (typeof v === 'number' ? formatCount(v) : 'n/a'),
            'tCO2e',
          ];
        default:
          return [
            (d) => d.metricValue,
            (v) => (typeof v === 'number' ? formatCount(v) : 'n/a'),
            'metric',
          ];
      }
    }

    if (options?.overMetric && options?.metric) {
      const [getNumerator, formatNumerator, numeratorSuffix] = getUtilFns(
        options.metric,
        true,
        false,
      );
      const [_0, _1, denominatorSuffix] = getUtilFns(
        options.overMetric,
        true,
        true,
      );

      return [
        getNumerator,
        formatNumerator,
        `${numeratorSuffix}/${denominatorSuffix}`,
      ];
    }

    return getUtilFns(options?.metric);
  }, [t, options?.metric, options?.overMetric]);

  const formatDimensionNameOrValue = useCallback<
    MetricsCubeFormattingUtils['formatDimensionNameOrValue']
  >(
    (kayaString, inDimension) => {
      if (!kayaString) return 'unknown';

      const normalizedKayaString = kayaString
        .toLowerCase()
        .trim()
        .replaceAll('account_taxonomy_', ''); // marker for account-specific taxonomies

      // Fallback through different possibilities to convert the string to a meaningful
      // and localized UI copy

      const formatted = (() => {
        // Metrics cube dimension name and descriptions
        const metricsCubeDimensionName = findTranslationOrNull(
          `metricsCubeDimensions.${normalizedKayaString}.name`,
        );
        if (metricsCubeDimensionName)
          return upperFirst(metricsCubeDimensionName);

        if (inDimension) {
          // Dimension values

          // Product materials
          if (
            inDimension.includes('material') &&
            hasMaterialName(t, normalizedKayaString)
          ) {
            return getMaterialName(t, normalizedKayaString);
          }

          // Country codes
          if (inDimension.toLowerCase().includes('country')) {
            const countryName = findTranslationOrNull(
              `countries.${normalizedKayaString.toUpperCase()}`,
            );
            if (countryName) return upperFirst(countryName);
          }

          // Climatiq activity types, used for Expenses
          if (inDimension.includes('expense')) {
            const climatiqActivityKey = Object.entries(
              Climatiq.ActivityId,
            ).find(([_, value]) => value === normalizedKayaString)?.[0];
            if (climatiqActivityKey) {
              const climatiqActivityName = findTranslationOrNull(
                `Expenses.expense.activityTypeValues.${climatiqActivityKey}`,
              );
              if (climatiqActivityName) return upperFirst(climatiqActivityName);
            }
          }

          // Transport modes (road, air...)
          if (
            inDimension.includes('transport') ||
            inDimension.includes('travel')
          ) {
            const transportModeName = findTranslationOrNull(
              `Transport.transport.transportModeValues.${normalizedKayaString}`,
            );
            if (transportModeName) return upperFirst(transportModeName);
          }

          // GHG Protocol Scopes
          if (inDimension.includes('ghg_protocol')) {
            const ghgProtocolScopeName = findTranslationOrNull(
              `ghgProtocolChapterTitles.${normalizedKayaString.replaceAll('.', '_')}`,
            );
            if (ghgProtocolScopeName) return upperFirst(ghgProtocolScopeName);
          }

          if (
            inDimension.includes('process') &&
            hasProcessStepName(t, normalizedKayaString)
          ) {
            return getProcessStepName(t, normalizedKayaString);
          }
        }

        // If no translation was found, fall back to a cleaned up version of the Kaya string
        return capitalizeWords(
          normalizedKayaString
            .replaceAll('_', ' ')
            .replaceAll('#', ' ')
            .replaceAll('slug', ''),
        );
      })();

      if (kayaString.includes('account_taxonomy_')) {
        // Add account-specific taxonomy marker
        return `${formatted} ⍟`;
      }
      return formatted;
    },
    [findTranslationOrNull, t],
  );

  const getMetricOrDimensionDescription = useCallback<
    MetricsCubeFormattingUtils['getMetricOrDimensionDescription']
  >(
    (kayaString) => {
      if (!kayaString) return undefined;

      const normalizedKayaString = kayaString.toLowerCase().trim();

      if (kayaString.includes('account_taxonomy_')) {
        return (
          <div className="flex flex-col gap-2">
            <p className="text-base font-normal">
              {formatDimensionNameOrValue(normalizedKayaString)}
            </p>
            {t('metricsCubeDimensions.accountTaxonomyDescription')}
          </div>
        );
      }

      if (!t.has(`metricsCubeDimensions.${normalizedKayaString}.description`)) {
        return '';
      }

      const examples = getExamples(normalizedKayaString, t);

      return (
        <div className="flex flex-col gap-2">
          <p className="text-base font-normal">
            {formatDimensionNameOrValue(normalizedKayaString)}
          </p>
          {t(`metricsCubeDimensions.${normalizedKayaString}.description`)}

          {examples.length > 0 && (
            <div className="flex flex-col gap-1 text-sm mt-2">
              <p className="text-sm font-medium">
                {t('metricsCubeDimensions.examples')}
              </p>
              <ul className="list-disc pl-4">
                {examples.map((example) => (
                  <li className="break-words" key={`example-${example}`}>
                    {example}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      );
    },
    [t, formatDimensionNameOrValue],
  );

  return {
    getMetricValue,
    formatMetricValue,
    metricSuffix,
    formatMetricName: formatDimensionNameOrValue, // for now it's the same logic
    formatDimensionNameOrValue,
    getMetricOrDimensionDescription,
  };
}

// Return all examples for a given dimension key
const getExamples = (key: string, t: ReturnType<typeof useTranslations>) => {
  let i = 0;
  const examples = [];

  while (t.has(`metricsCubeDimensions.${key}.examples.${i}`)) {
    examples.push(t(`metricsCubeDimensions.${key}.examples.${i}`));
    i++;
  }

  return examples;
};
