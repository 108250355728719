import { upperFirst } from 'lodash';

// Tranform an internal SNAKE_CASE_KEY to a human readable string
export function humanizeKey(key: string) {
  return key
    .toLowerCase()
    .split('/')
    .map((word) => upperFirst(word.replace(/_/g, ' ')))
    .join(' - ');
}
